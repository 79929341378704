<template>
	<div class="layout-dashboard">
        <div class="grid">
            <div class="col-12 md:col-6 lg:col-3"
                 @click="$router.push('/visitas')">
                <div class="card p-0 widget-overview-box widget-overview-box-1">
                    <div class="flex align-items-center justify-content-start pt-3 pb-2 px-4">
                        <i class="pi pi-users text-6xl"></i>

                        <div class="pl-3 overflow-hidden">
                            <span class="header block white-space-nowrap overflow-hidden">VISITAS</span>
                            <span class="block text-4xl font-bold" :style="{'color': ($appState.colorScheme === 'light' ? '#035388' : '#1992D4')}">3882</span>
                        </div>
                    </div>
                    <img
                        :src="'layout/images/dashboard/users' + ($appState.colorScheme === 'light' ? '' : '-dark') + '.svg'"
                        class="w-full" alt="users">
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-3"
                 @click="$router.push('/projetos')">
                <div class="card p-0 widget-overview-box widget-overview-box-2">
                    <div class="flex align-items-center justify-content-start pt-3 pb-2 px-4">
                        <i class="pi pi-map text-6xl"></i>
                        <div class="pl-3 overflow-hidden">
                            <span class="header block white-space-nowrap overflow-hidden">PROJETOS</span>
                            <span class="block text-4xl font-bold" :style="{'color': ($appState.colorScheme === 'light' ? '#CB6E17' : '#DE911D')}">532</span>
                        </div>
                    </div>
                    <img
                        :src="'layout/images/dashboard/locations' + ($appState.colorScheme === 'light' ? '' : '-dark') + '.svg'"
                        class="w-full" alt="users">
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-3"
                 @click="$router.push('/vendas')">
                <div class="card p-0 widget-overview-box widget-overview-box-3">
                    <div class="flex align-items-center justify-content-start pt-3 pb-2 px-4">
                        <i class="pi pi-directions text-6xl"></i>
                        <div class="pl-3 overflow-hidden">
                            <span class="header block white-space-nowrap overflow-hidden">VENDAS</span>
                            <span class="block text-4xl font-bold" :style="{'color': ($appState.colorScheme === 'light' ? '#147D64' : '#27AB83')}">12.6%</span>
                        </div>
                    </div>
                    <img
                        :src="'layout/images/dashboard/rate' + ($appState.colorScheme === 'light' ? '' : '-dark') + '.svg'"
                        class="w-full" alt="users">
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-3"
                 @click="$router.push('/leads')">
                <div class="card p-0 widget-overview-box widget-overview-box-4 md:h-full md:relative">
                    <div class="flex align-items-center justify-content-start pt-3 pb-2 px-4">
                        <i class="pi pi-comments text-6xl"></i>
                        <div class="pl-3 overflow-hidden">
                            <span class="header block white-space-nowrap overflow-hidden">LEADS</span>
                            <span class="block text-4xl font-bold" :style="{'color': ($appState.colorScheme === 'light' ? '#6551B7' : '#A896FF')}">440</span>
                        </div>
                    </div>
                    <img :src="'layout/images/dashboard/interactions' + ($appState.colorScheme === 'light' ? '' : '-dark') + '.svg'"
                        class="w-full md:absolute md:bottom-0" alt="users">
                </div>
            </div>

            <div class="col-12 lg:col-6">
                <div class="card widget-overview-chart h-full">
                    <div class="card-header">
                        <span class="font-bold">Orçamentos Semana</span>
                        <Dropdown :options="overviewWeek" v-model="selectedOverviewWeek" optionLabel="name" @change="changeOverviewWeek($event)"></Dropdown>
                    </div>

                    <div class="graph mb-3">
                        <Chart ref="overview" type="bar" :data="overviewChart" :options="overviewChartOptions" id="overview-chart"></Chart>
                    </div>

                    <div class="graph-footer flex align-items-center justify-content-end">
                        <Button type="button" label="Elaboração" class="p-button-outlined p-button-rounded p-button-sm font-bold mr-3 py-1 first-data-button"></Button>
                        <Button type="button" label="Convertido" class="p-button-outlined p-button-rounded p-button-sm font-bold py-1 second-data-button"></Button>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-3">
                <div class="card p-0 widget-people h-full relative">
                    <div class="header font-bold py-4 px-3">Obras</div>
                    <div class="overflow-y-auto" style="max-height: calc(100% - 100px)">
                        <div class="mb-4 px-3 flex align-items-center justify-content-start">
                            <Avatar label="ES" size="large" shape="circle" class="text-base font-bold"
                                :style="{'background-color':'rgba(101, 214, 173, 0.1)', 'color': '#27AB83', 'border': '1px solid #65D6AD'}"></Avatar>
                            <div class="ml-3">
                                <span class="name block">João da Silva</span>
                                <span class="position block">54kwp</span>
                            </div>
                        </div>
                        <div class="mb-4 px-3 flex align-items-center justify-content-start">
                            <Avatar label="RJ" size="large" shape="circle" class="text-base font-bold"
                                :style="{'background-color':'rgba(250, 219, 95, 0.1)', 'color': '#DE911D', 'border': '1px solid #FADB5F'}"></Avatar>
                            <div class="ml-3">
                                <span class="name block">Maria da Silva</span>
                                <span class="position block">18kwp</span>
                            </div>
                        </div>
                        <div class="mb-4 px-3 flex align-items-center justify-content-start">
                            <Avatar label="SP" size="large" shape="circle" class="text-base font-bold"
                                :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
                            <div class="ml-3">
                                <span class="name block">José da Silva</span>
                                <span class="position block">120kwp</span>
                            </div>
                        </div>
                        <div class="mb-4 px-3 flex align-items-center justify-content-start">
                            <Avatar label="SP" size="large" shape="circle" class="text-base font-bold"
                                :style="{'background-color':'rgba(43, 176, 237, 0.1)', 'color': '#127FBF', 'border': '1px solid #2BB0ED'}"></Avatar>
                            <div class="ml-3">
                                <span class="name block">José da Silva</span>
                                <span class="position block">45kwp</span>
                            </div>
                        </div>
                        <div class="mb-4 px-3 flex align-items-center justify-content-start">
                            <Avatar label="MG" size="large" shape="circle" class="text-base font-bold"
                                :style="{'background-color':'rgba(250, 219, 95, 0.1)', 'color': '#DE911D', 'border': '1px solid #FADB5F'}"></Avatar>
                            <div class="ml-3">
                                <span class="name block">Alberto da Silva</span>
                                <span class="position block">15kwp</span>
                            </div>
                        </div>
                    </div>
                    <div @click="$router.push('/obras')"
                        class="footer cursor-pointer flex align-items-center justify-content-center p-3 absolute bottom-0 w-full">
                        <span class="font-bold mr-2">Mais detalhes</span>
                        <i class="pi pi-chevron-right"></i>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-3">
                <div class="card widget-profile-completion">
                    <div class="flex justify-content-center">
                        <img :src="'layout/images/dashboard/completion-graph-' + ($appState.colorScheme === 'light' ? 'light' : 'dark') + '.svg'"
                            alt="graph" class="w-auto md:w-full mb-5">
                    </div>

                    <div class="mb-4 flex align-items-center justify-content-start">
                        <Avatar icon="pi pi-user-edit" size="large" shape="circle" class="text-base font-bold"
                            :style="{'background-color':'rgba(250, 219, 95, 0.1)', 'color': '#DE911D', 'border': '1px solid #FADB5F'}"></Avatar>
                        <div class="ml-3">
                            <span class="label block">Últimos clientes cadastrados</span>
                            <span class="action block font-bold" @click="$router.push('/clientes')">Abrir todos</span>
                        </div>
                    </div>

                    <div class="mb-4 flex align-items-center justify-content-start">
                        <Avatar icon="pi pi-send" size="large" shape="circle" class="text-base font-bold"
                            :style="{'background-color':'rgba(250, 219, 95, 0.1)', 'color': '#DE911D', 'border': '1px solid #FADB5F'}"></Avatar>
                        <div class="ml-3">
                            <span class="label block">Últimas Obras</span>
                            <span class="action block font-bold" @click="$router.push('/obras')">Abrir todos</span>
                        </div>
                    </div>

                    <div class="mb-4 flex align-items-center justify-content-start">
                        <Avatar icon="pi pi-video" size="large" shape="circle" class="text-base font-bold"
                            :style="{'background-color':'rgba(250, 219, 95, 0.1)', 'color': '#DE911D', 'border': '1px solid #FADB5F'}"></Avatar>
                        <div class="ml-3">
                            <span class="label block">Novos Orçamentos</span>
                            <span class="action block font-bold" @click="$router.push('/orcamentos')">R$ 19.000.000,00</span>
                        </div>
                    </div>

                    <div class="mb-4 flex align-items-center justify-content-start">
                        <Avatar icon="pi pi-briefcase" size="large" shape="circle" class="text-base font-bold"
                            :style="{'background-color':'rgba(250, 219, 95, 0.1)', 'color': '#DE911D', 'border': '1px solid #FADB5F'}"></Avatar>
                        <div class="ml-3">
                            <span class="label block">Pedido Confirmado</span>
                            <span class="action block font-bold" @click="$router.push('/vendas')">R$ 25.000.000,00</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 md:col-6 lg:col-4" @click="$router.push('/projetos')">
                <div class="card widget-image-stats relative">
                    <div class="card-header">
                        <span class="font-bold">Últimos Projetos</span>
                        <div class="header-right-section flex align-items-center">
                            <Button type="button" icon="pi pi-angle-left"
                                class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold mr-2 p-2"></Button>
                            <Button type="button" icon="pi pi-angle-right"
                                class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold mr-3 p-2"></Button>
                            <span class="font-bold">1</span><span>/5</span>
                        </div>
                    </div>
                    <img src="layout/images/dashboard/ultima-usina.png" alt="product" class="w-full my-4">

                    <div class="flex align-items-center justify-content-between mb-2">
                        <span class="font-bold">Em Análise</span>
                        <span>%40</span>
                    </div>
                    <ProgressBar :value="40" :showValue="false" class="orange-bar mb-4"></ProgressBar>

                    <div class="flex align-items-center justify-content-between mb-2">
                        <span class="font-bold">Aprovados</span>
                        <span>%89</span>
                    </div>
                    <ProgressBar :value="89" :showValue="false" class="green-bar mb-4"></ProgressBar>

                    <div class="flex align-items-center justify-content-between mb-2">
                        <span class="font-bold">Rejeitados</span>
                        <span>%8</span>
                    </div>
                    <ProgressBar :value="82" :showValue="false" class="green-bar"></ProgressBar>
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-4" @click="$router.push('/calendario')">
                <div class="card widget-tasks">
                    <div class="card-header block mb-3 px-2">
                        <span class="block font-bold mb-3">Últimas Tarefas</span>
                        <span class="block font-bold text-2xl mb-4">Entrega de usina 89kwp</span>
                        <span class="badge block w-min font-bold mb-4 px-2 py-1 border-1 border-solid">Engenharia</span>
                        <span class="clock"><i class="pi pi-clock mr-1"></i>Jan 15 . <span
                            class="font-bold">4 dias 36 minutos</span></span>
                    </div>
                    <p class="px-2 mb-8">Vitória - ES. João da Silva. Solar Edge. R$ 158.000,00 </p>
                    <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
                        <div class="footer-left flex align-items-center">
                            <Button type="button" icon="pi pi-angle-left"
                                    class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold mr-2 p-2 w-2rem h-2rem"></Button>
                            <Button type="button" icon="pi pi-angle-right"
                                    class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold mr-3 p-2 w-2rem h-2rem"></Button>
                            <span class="font-bold">4</span><span>/ 52</span>
                        </div>
                        <div class="footer-right">
                            <AvatarGroup class="w-9rem justify-content-evenly">
                                <Avatar image="demo/images/avatar/amyelsner.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/asiyajavayant.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/onyamalimba.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/ionibowcher.png" shape="circle"></Avatar>
                                <Avatar image="demo/images/avatar/xuxuefeng.png" shape="circle"></Avatar>
                            </AvatarGroup>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <div class="card widget-ratings">
                    <div class="card-header font-bold mb-4">Projetos Pendentes</div>
                    <TabView orientation="left">
                        <TabPanel header="Região 1">
                            <table class="w-full">
                                <tbody>
                                <tr>
                                    <td class="w-4">ES</td>
                                    <td class="w-1"><span class="font-bold">3.9</span></td>
                                    <td class="w-2">
                                        <div
                                            class="flex align-items-center justify-content-between px-2 py-1 warning-badge">
                                            <i class="pi pi-circle-off"></i>
                                            <span class="font-bold ml-1">0.2</span>
                                        </div>
                                    </td>
                                    <td class="w-4">
                                        <div class="flex align-items-center justify-content-center low-tag p-1">
                                            <span class="font-bold">BAIXA PRIORIDADE</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="w-4">MG</td>
                                    <td class="w-1"><span class="font-bold">2.8</span></td>
                                    <td class="w-2">
                                        <div
                                            class="flex align-items-center justify-content-between px-2 py-1 warning-badge">
                                            <i class="pi pi-circle-off"></i>
                                            <span class="font-bold ml-1">0.9</span>
                                        </div>
                                    </td>
                                    <td class="w-4">
                                        <div class="flex align-items-center justify-content-center high-tag p-1">
                                            <span class="font-bold">ALTA PRIORIDADE</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="w-4">SP</td>
                                    <td class="w-1"><span class="font-bold">4.6</span></td>
                                    <td class="w-2">
                                        <div
                                            class="flex align-items-center justify-content-between px-2 py-1 success-badge">
                                            <i class="pi pi-arrow-up"></i>
                                            <span class="font-bold ml-1">1.7</span>
                                        </div>
                                    </td>
                                    <td class="w-4">
                                        <div class="flex align-items-center justify-content-center high-tag p-1">
                                            <span class="font-bold">ALTA PRIORIDADE</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="w-4">AC</td>
                                    <td class="w-1"><span class="font-bold">9.0</span></td>
                                    <td class="w-2">
                                        <div class="flex align-items-center justify-content-between px-2 py-1 danger-badge">
                                            <i class="pi pi-arrow-down"></i>
                                            <span class="font-bold ml-1">4.5</span>
                                        </div>
                                    </td>
                                    <td class="w-4">
                                        <div class="flex align-items-center justify-content-center low-tag p-1">
                                            <span class="font-bold">BAIXA PRIORIDADE</span>
                                        </div>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel header="Região 2">
                            <table class="w-full">
                                <tbody>
                                <tr>
                                    <td class="w-4">RS</td>
                                    <td class="w-1"><span class="font-bold">2.8</span></td>
                                    <td class="w-2">
                                        <div
                                            class="flex align-items-center justify-content-between px-2 py-1 warning-badge">
                                            <i class="pi pi-circle-off"></i>
                                            <span class="font-bold ml-1">0.9</span>
                                        </div>
                                    </td>
                                    <td class="w-4">
                                        <div class="flex align-items-center justify-content-center high-tag p-1">
                                            <span class="font-bold">ALTA PRIORIDADE</span>
                                        </div>
                                    </td>
                                </tr>


                                </tbody>
                            </table>
                        </TabPanel>
                    </TabView>
                </div>
            </div>

            <div class="col-12 md:col-6 lg:col-6" @click="$router.push('/leads')">
                <div class="card widget-timeline">
                    <div class="card-header font-bold mb-4">Timeline</div>
                    <div class="flex mb-2 p-3 pb-0">
                        <div class="flex flex-column align-items-center">
                            <span
                                class="flex align-items-center justify-content-center border-circle p-2 timeline-icon timeline-icon-1">
                                <i class="pi pi-twitter"></i>
                            </span>
                            <div class="h-full timeline-line-1" style="width: 2px; min-height: 4rem"></div>
                        </div>

                        <div class="ml-3 w-full">
                            <div class="flex align-items-center justify-content-between timeline-event-header mb-3 mt-2">
                                <span><span class="font-bold">João da Silva</span> enviou projeto 1877 para engenharia.</span>
                                <span class="font-bold subtext">46m</span>
                            </div>
                            <div class="p-3"
                                :style="{'background-color':$appState.colorScheme === 'dark'? 'rgba(227, 248, 255, 0.1)' :'rgba(227, 248, 255, 0.5)', 'border-radius': '6px'}">
                                <div class="flex align-items-center justify-content-between mb-2">
                                    <img src="demo/images/avatar/xuxuefeng.png" alt="avatar" class="w-2rem">
                                    <AvatarGroup class="w-6rem justify-content-evenly">
                                        <Avatar image="demo/images/avatar/amyelsner.png" shape="circle"></Avatar>
                                        <Avatar image="demo/images/avatar/asiyajavayant.png" shape="circle"></Avatar>
                                        <Avatar image="demo/images/avatar/onyamalimba.png" shape="circle"></Avatar>
                                    </AvatarGroup>
                                </div>
                                <span class="font-bold">Alberto dos Santos</span>
                                <p class="subtext text-sm line-height-3 mb-3" style="max-width: 28rem">Rio de Janeiro, RJ, 87kwp</p>

                                <ProgressBar :value="50" :showValue="false" class="w-6"></ProgressBar>
                            </div>
                        </div>
                    </div>
                    <div class="flex mb-2 p-3 pb-0">
                        <div class="flex flex-column align-items-center">
                            <span
                                class="flex align-items-center justify-content-center border-circle p-2 timeline-icon timeline-icon-2">
                                <i class="pi pi-star"></i>
                            </span>
                            <div class="h-full timeline-line-2" style="width: 2px; min-height: 2rem"></div>
                        </div>

                        <div class="ml-3 w-full">
                            <div class="flex align-items-center justify-content-between timeline-event-header mb-3 mt-2">
                                <span><span class="font-bold">Maria de Souza</span> cotação de Jinko 98kwp.</span>
                                <span class="font-bold subtext">1h 51m</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex mb-4 p-3 pb-0 shadow-2" style="border-radius: 10px">
                        <div class="flex flex-column align-items-center">
                            <span
                                class="flex align-items-center justify-content-center border-circle p-2 timeline-icon timeline-icon-3">
                                <i class="pi pi-link"></i>
                            </span>
                            <div class="h-full timeline-line-3" style="width: 2px; min-height: 4rem"></div>
                        </div>

                        <div class="ml-3 w-full">
                            <div class="flex align-items-center justify-content-between timeline-event-header mb-3 mt-2">
                                <span><span class="font-bold">Jorge Gomes</span> compartilhou fotos de seu último projeto.</span>
                                <span class="font-bold subtext">12h 14m</span>
                            </div>
                            <div class="flex align-items-center justify-content-start mb-2">
                                <img src="layout/images/dashboard/bg-detail.svg" alt="bg" class="w-2rem">
                                <span class="font-bold ml-2">01.projeto_sul_001</span>
                            </div>
                            <div class="flex align-items-center justify-content-start mb-3">
                                <img src="layout/images/dashboard/bg-detail.svg" alt="bg" class="w-2rem">
                                <span class="font-bold ml-2">02.projeto_norte_002</span>
                            </div>
                        </div>
                    </div>
<!--                    <div class="timeline-footer flex align-items-center justify-content-center cursor-pointer">-->
<!--                        <i class="pi pi-chevron-down p-1"></i>-->
<!--                        <span class="font-bold ml-2">Abrir todos</span>-->
<!--                    </div>-->
                </div>
            </div>
            <div class="col-12 lg:col-6" @click="$router.push('/fluxocaixa')">
                <div class="card widget-graph p-5">
                    <div class="card-header font-bold mb-5">Receitas e Despesas</div>

                    <Chart type="line" :data="chartData" :options="chartOptions" id="nasdaq-chart"></Chart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    watch: {
        '$appState.colorScheme'() {
            this.overviewChartOptions = this.getOrdersOptions();
            this.overviewChart.datasets[1].backgroundColor[0] = this.$appState.colorScheme === 'dark' ? '#879AAF' : '#E4E7EB';
        }
    },
	data() {
		return {
			overviewChart: {
				labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
				datasets: [
					{
						data: [2, 1, 0.5, 0.6, 0.5, 1.3, 1],
						borderColor: [
							'#FADB5F',
						],
						pointBorderColor: 'transparent',
						pointBackgroundColor: 'transparent',
						type: 'line',
						fill: false,
					},
					{
						data: [4.88, 3, 6.2, 4.5, 2.1, 5.1, 4.1],
						backgroundColor: [this.$appState.colorScheme === 'dark' ? '#879AAF' : '#E4E7EB'] ,
						fill: true,
						borderRadius: 10,
						borderSkipped: 'top bottom',
						barPercentage: 0.3
					}
				]
			},
			overviewChartOptions: null,
			overviewWeek: [
				{name: 'Semana Passada', code: '0'},
				{name: 'Esta Semana', code: '1'}
			],
			selectedOverviewWeek: null,
			chartData: {
				labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
				datasets: [
					{
						data: [11, 17, 30, 60, 88, 92],
						borderColor: 'rgba(25, 146, 212, 0.5)',
						pointBorderColor: 'transparent',
						pointBackgroundColor: 'transparent',
						fill: false,
						tension: .4
					},
					{
						data: [11, 19, 39, 59, 69, 71],
						borderColor: 'rgba(25, 146, 212, 0.5)',
						pointBorderColor: 'transparent',
						pointBackgroundColor: 'transparent',
						fill: false,
						tension: .4
					},
					{
						data: [11, 17, 21, 30, 47, 83],
						backgroundColor: 'rgba(25, 146, 212, 0.2)',
						borderColor: 'rgba(25, 146, 212, 0.5)',
						pointBorderColor: 'transparent',
						pointBackgroundColor: 'transparent',
						fill: true,
						tension: .4
					}
				]
			},
			chartOptions: {
				plugins: {
					legend: {
						display: false
					}
				},
				scales: {
					y: {
						max: 100,
						min: 0,
						ticks: {
							color: '#A0A7B5'
						}
					},
					x: {
						grid: {
							display: true,
						},
						ticks: {
							color: '#A0A7B5',
							beginAtZero: true,
						}
					}
				}
			},
			chart: null
		}
	},
	mounted() {
        this.selectedOverviewWeek = this.overviewWeek[0];
		this.overviewChartOptions = this.getOrdersOptions();
		this.getGradient();
	},
	methods: {
		getGradient() {
			this.chart = document.getElementsByTagName('canvas')[1].getContext('2d');
			const gradientStroke = this.chart.createLinearGradient(100, 0, 1150, 100);
			gradientStroke.addColorStop(0, 'rgba(21, 184, 194, 0)');
			gradientStroke.addColorStop(0.5, 'rgba(25, 146, 212, 1)');
			gradientStroke.addColorStop(1, 'rgba(23, 88, 124, 1)');

			const gradientFill = this.chart.createLinearGradient(0, 0, 1150, 0);
			gradientFill.addColorStop(1, 'rgba(25, 146, 212, 0.34)');
			gradientFill.addColorStop(0, 'rgba(232, 247, 255, 0.34)');

			this.chartData.datasets[0].borderColor = gradientStroke;
			this.chartData.datasets[1].borderColor = gradientStroke;
			this.chartData.datasets[2].borderColor = gradientStroke;

			this.chartData.datasets[2].backgroundColor = gradientFill;
		},
		getOrdersOptions() {
			return {
				plugins: {
					legend: {
						display: false
					}
				},
				responsive: true,
				hover: {
					mode: 'index'
				},
				scales: {
					y: {
						max: 7,
						min: 0,
						ticks: {
							stepSize: 0,
							callback: function(value, index) {
								if (index === 0) {
									return value;
								}
								else {
									return value + 'k';
								}
							},
							color: this.$appState.colorScheme === 'dark' ? '#DBE2EB' : '#3E4C59'
						},
						grid: {
							borderDash: [2, 2],
							color: this.$appState.colorScheme === 'dark' ? '#4E657F' : '#E4E7EB',
							drawBorder: false,
						},
					},
					x: {
						grid: {
							display: false,
						},
						ticks: {
							beginAtZero: true,
							color: this.$appState.colorScheme === 'dark' ? '#DBE2EB' : '#3E4C59'
						}
					}
				}
			};
		},
		changeOverviewWeek(event) {
			const dataSet1 = [
				[2, 1, 0.5, 0.6, 0.5, 1.3, 1],
				[4.88, 3, 6.2, 4.5, 2.1, 5.1, 4.1]
			];
			const dataSet2 = [
				[3, 2.4, 1.5, 0.6, 4.5, 3.3, 2],
				[3.2, 4.1, 2.2, 5.5, 4.1, 3.6, 3.5],
			];

			if (event.value.code === '1') {
				this.overviewChart.datasets[0].data = dataSet2[parseInt('0')];
				this.overviewChart.datasets[1].data = dataSet2[parseInt('1')];
			} else {
				this.overviewChart.datasets[0].data = dataSet1[parseInt('0')];
				this.overviewChart.datasets[1].data = dataSet1[parseInt('1')];
			}

            this.$refs.overview.refresh();
		}
	}
}
</script>

<style scoped>

</style>
